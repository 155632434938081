code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


body {
  margin: 0;
  background: #fafdf6;
  color: #2d2a32;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after { box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
